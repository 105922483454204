/**
 * Allows to check the type for any object
 */
class Typecheck {

  /**
   * Check whether the value is a string
   * 
   * @param value the value to check the string type for
   * @returns true when the value is a string type, false otherwise
   */
  isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }

  /**
   * Check whether the value is an object. The method excludes arrays
   * and null.
   * 
   * @param value the value to check the object type for
   * @returns true when the value is an object, false otherwise
   */
  isObject(value: any): boolean {
    return typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null
  }
}

export default new Typecheck();