import RouteOption from "@/data/route.option";

const ROUTE_OPTIONS: Array<RouteOption> = [
  new RouteOption(
    'Hashing',
    'mdi-pound-box'
  ),
  // new RouteOption(
  //   'Tasker',
  //   'mdi-checklist'
  // ),
  new RouteOption(
    'Socket',
    'mdi-cable-data'
  ),
  // new RouteOption(
  //   'UUIDs',
  //   'mdi-fingerprint'
  // ),
  // new RouteOption(
  //   'JsSandbox',
  //   'mdi-laptop'
  // ),
  // new RouteOption(
  //   'JSONEditor',
  //   'mdi-code'
  // ),
  new RouteOption(
    'Settings',
    'mdi-tune'
  )
]


const HOME_ROUTE: RouteOption = new RouteOption(
  'Home',
  'mdi-home'
)
.setPath('/');

export { ROUTE_OPTIONS, HOME_ROUTE };