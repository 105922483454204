<template>
  <v-app ID="app">
    <Navigation />
    <v-main>
      <div class="main-content">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Vue from "vue";
import Navigation from '@/components/Navigation.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Navigation
  },
  mounted() {
    // initial loading of theme
    // TODO refactor me since this logic is replicated in the Settings.vue component
    const theme = this.$store.state.theme;
    if (theme === 'dark') {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  }
})
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-content {
  padding: 20px 0px 0px 70px;
  height: 100%;
}
</style>
