















































import { ROUTE_OPTIONS, HOME_ROUTE } from '../data/route.options';
import Vue from 'vue';

export default Vue.extend({
  name: 'Navigation',
  data: () => {
    return {
      options: [HOME_ROUTE].concat(ROUTE_OPTIONS),
      mini: true,
      drawer: true,
      visitingPage: ''
    }
  },
  methods: {
    goTo(ev: any, option: any): void {
      ev.stopPropagation();
      this.$router.push(option.path);
      this.mini = true;
      this.visitingPage = option.name
    }
  },
  watch:{
    $route (to){
      this.visitingPage = to.name;
    }
} 
})
