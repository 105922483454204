import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import { ROUTE_OPTIONS, HOME_ROUTE } from '@/data/route.options';

Vue.use(VueRouter);

// lazy load all other routes components
const options: Array<RouteConfig> = ROUTE_OPTIONS.map((route) => {
  return {
      path: route.path,
      name: route.name,
      component: () => import(`@/views/${route.view}`)
    }
  });

// eager load home route component
const home: Array<RouteConfig> = [{
  path: HOME_ROUTE.path,
  name: HOME_ROUTE.name,
  component: Home
}];

// concat all routes
const routes: Array<RouteConfig> = home.concat(options);

// create the router
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
