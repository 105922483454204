import Vue from 'vue'
import Vuex from 'vuex'
import { settings, types } from '@/common/settings';

Vue.use(Vuex)
export default new Vuex.Store({
  // load the initial values via settings
  state: {
    color: settings.loadOrDefault(types.COLOR, 'pink'),
    theme: settings.loadOrDefault(types.THEME, 'dark'),
    editorTheme: settings.loadOrDefault(types.EDITOR_COLOR, 'base16-dark')
  },
  mutations: {
    setColor (state, color) {
      state.color = color;
      settings.save(types.COLOR, color);
    },
    setTheme (state, theme) {
      state.theme = theme;
      settings.save(types.THEME, theme);
    },
    setEditorTheme(state, editorTheme) {
      state.editorTheme = editorTheme;
      settings.save(types.EDITOR_COLOR, editorTheme);
    }
  },
  actions: {
  },
  modules: {
  }
})
