/**
 * This class defines a route options definition. It is used to 
 * create routes as well as to render the navigation menu component
 */
export default class RouteOption {
  /**
   * The name of the route
   */
  name: string
  /**
   * Represents the path for this route
   */
  path: string 
  /**
   * The icon to display for this route
   */
  icon: string
  /**
   * The name of the vue component to load for this route
   */
  view: string
  constructor(name: string, icon: string) {
    this.name = name;
    this.path = `/${name.toLocaleLowerCase()}`;
    this.icon = icon;
    this.view = `${name}.vue`;
  }

  /**
   * Allows to set the path value for this route options after creation;
   * (This is useful for customizing the path without changing its name)
   * (name: Home -> path: /)
   * 
   * @param path the path to set
   * @returns RouteOprtion
   */
  setPath(path: string): RouteOption {
    this.path = path;
    return this;
  }
}