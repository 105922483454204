

























import Vue from "vue";
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  name: "Home",
  metaInfo(): MetaInfo {
    return {
      title: 'Coderpunk tools Home',
      meta: [
        { name: 'Coderpunk tools Home', content: 'A collection of instruments crafted to assist a coder in it\'s daily job' },
        { property: 'og:title', content: 'Coderpunk tools Home' },
        { property: 'og:site_name', content: 'Coderpunk tools' },
        { property: 'og:description', content: 'A collection of instruments crafted to assist a coder in it\'s daily job' },
        { property: 'og:type', content: 'webapp'},
        { property: 'og:url', content: 'https://tools.coderpunk.tech' },
        { property: 'og:image', content: '@/assets/Coderpunk.png' }    
      ]
    }
  }
});
