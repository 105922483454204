import typecheck from '@/common/typecheck';

/**
 * Enum defining the different types of settings available
 */
 enum Type {
  COLOR = 'color',
  THEME = 'theme',
  EDITOR_COLOR = 'editorColor'
}

/**
 * Allows to save, load, delete values to a {@link Storage} obj
 */
class Settings {
  /**
   * The local storage instance
   */
  storage: Storage

  /**
   * Define the possible values for each setting type
   */
  values: Map<Type, Array<any>>

  /**
   * Build a settings object
   * @param storage the local storage instance
   */
  constructor(storage: Storage) {
    // set the storage
    this.storage = storage;
    // set the values
    this.values = new Map();
    this.values.set(Type.COLOR, ['amber', 'blue', 'brown', 'cyan', 'deep-purple', 'green', 'indigo', 'light-blue', 'lime', 'orange', 'pink', 'purple', 'red']);
    this.values.set(Type.THEME, ['dark', 'light']);
    this.values.set(Type.EDITOR_COLOR, [
      'base16-dark',
      'darcula',
      'dracula',
      'material',
      'default',
      'duotone-light',
      'elegant',
      'idea',
      'monokai',
      'railscasts',
      'shadowfox',
      'seti',
      'oceanic-next',
      'mdn-like',
      'yeti',
      'nord'
    ]);
  }

  /**
   * Save setting value to the storage
   * 
   * @param name the name of the setting to save
   * @param value the value of the setting to save
   */
  save(name: string, value: object | string): void {
    if (typecheck.isString(value)) {
      return this.storage.setItem(name, value as string);
    }
    return this.storage.setItem(name, JSON.stringify(value));
  }

  /**
   * Load a setting value by name or returns the provided default value when not found
   * 
   * @param name the name of the setting to load the value for
   * @param defaultValue the default value to return if the setting value is not found
   * @returns the setting value or the default value when not found
   */
  loadOrDefault(name: string, defaultValue: object | string): object | string {
    const found = this.storage.getItem(name);

    if (found) {
      return typecheck.isString(found) ? found : JSON.parse(found);
    }
    
    return defaultValue;
  }

  /**
   * Delete a setting value by name
   * 
   * @param name the name of the setting value to delete
   */
  delete(name: string): void {
    this.storage.removeItem(name);
  }

  /**
   * Get all the possible values for a setting type, or return the default value when not found.
   * 
   * @param type the type of settings to get the values for
   * @param defaultValue the default value to return when values not found
   * @returns either the found values for the setting type or the default value provided
   */
  valueForOrDefault(type: Type, defaultValue: Array<any>): Array<any> {
    const values: Array<any> | undefined = this.values.get(type);
    if (!values) {
      return defaultValue;
    }
    return values;
  }
}

// singleton instance lazy initialized
let singletonInstance: Settings | null = null

// export a function that takes in the storage
export const settings = (() => { 
  if (singletonInstance === null) {
    singletonInstance = new Settings(localStorage);
  }
  return singletonInstance;
})();
// export the settings types
export const types = Type;